.deepDive {
  &Charts {
    margin-top: 1px;

    &Container {
      position: relative;

      &Item {
        padding: 20px;
      }

      &TodayDivider {
        right: -30px;

        &::before {
          height: 508px;
        }
      }
    }
  }

  &DataMonitor {
    &Container {
      align-items: top;
    }
  }

  &ReportBlock {
    margin-top: 0;
    margin-left: 0;
  }

  &DataMonitorData {
    height: 100%;
  }
}

.dataMonitorData {
  margin-top: 10px;
  height: 350px;
}

.dataMonitorDataLink {
  margin-top: 20px;
  height: 250px;
}

.noMarginBottom {
  margin-bottom: 0;
}
