@import "../../scss/variables.scss";

.rightIconGroup {
  position: absolute;
  padding-right: inherit;
  right: 0;

  > span {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.dragIcon {
  cursor: grab;
  color: $grey2;
  transform: rotate(90deg);
}

.dragIconWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
