@import "../../scss/variables.scss";

.menuButton {
    cursor: pointer;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: $blue1;
}
  