@import "../../scss/variables.scss";

.userLogo {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: none;
  background: $purple1 linear-gradient(0deg, rgba(95, 50, 152, 1) 0%, rgba(156, 114, 208, 1) 100%);
  color: $white;
  font-size: 1.5em;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: none;
}

.menuListItems {
  > li:not(:last-child) {
    cursor: initial;
    background-color: transparent;
  }

  > li:last-child {
    border-top: 1px solid $grey1;
  }
}

.userSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid $grey1;
  white-space: nowrap;

  .userLogo {
    margin-right: 10px;
  }
}

.menuIcon {
  height: 75px;
  padding: 0 10px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  border-radius: 0;
  width: auto;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  outline: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: transparent;

  span {
    position: relative;
  }
}

.menuItemsSection {
  h6 {
    padding: 10px 0 0 10px;
  }
}
