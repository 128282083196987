@import "../../scss/variables.scss";

.navigation {
  padding-left: 0;
  margin-bottom: 0;
  margin: 0;
  height: 75px;
  list-style: none;
  display: flex;
  flex-direction: row;

  & > li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navLeftBorder {
    border-left: 1px solid $grey1;
  }
}

.searchText {
  padding-left: 10px;
}

.myp360 {
  padding: 0 20px;
}

.navSearchBox {
  padding: 0 5px;

  a,
  a:hover,
  a:active {
    font-weight: bold;
    color: $grey2;
  }
}

.area_disable {
  pointer-events: none;
}
