.header {
  background-color: white;
  height: 75px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.navbarBrand img {
  height: 48px;
  margin: 0 12px;
}

.disable_area {
  pointer-events: none;
}
