.link {
  text-decoration: none;
}

.data {
  margin-top: 5px;
  margin-bottom: 5px;
}

.dataTop {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  width: 100%;

  a {
    color: #969696;
    display: inline-block;
  }
}

.actionCol {
  position: absolute;
  top: 0;
  right: 15px;
  padding: 5px;
}

.articletype {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 5px;
}

.title {
  color: #c94576;
  font-size: 14px;
}

.content {
  margin-top: 10px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product {
  margin-top: 10px;
  text-transform: uppercase;
}
