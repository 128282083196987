@import "../../scss/variables.scss";

footer {
  background-color: $grey3;
  color: $white;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  z-index: 1;
}

.disclaimer {
  padding-right: 30px;
}

.privacyPolicy {
  color: #fff;
  text-decoration: underline !important;
}