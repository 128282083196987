@import-normalize;
@import "./scss/variables.scss";

@font-face {
  font-family: "FSMe";
  src: url("./fonts/FSMeWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FSMe-Bold";
  src: url("./fonts/FSMeWeb-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FSMe-Light";
  src: url("./fonts/FSMe-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

.content {
  font-size: 14px;
  width: 100%;
  height: calc(100% - 268px);
  margin-bottom: 150px;
  padding: 24px 0;
  /* The height of the header */
  margin-top: 112px;
}

a {
  color: $primary;
}

a:link {
  text-decoration: none;
}

a:active {
  color: inherit;
}

/*       utility classes       */

/*       flex/alignment        */

.flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
}

.v-align-middle {
  vertical-align: middle;
}

/*            colors           */

.color-pink {
  color: $primary;
}

/*          text utils         */

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

/*            forms           */
.form-row {
  margin-bottom: 40px;
}

/*            cursor          */
.pointer {
  cursor: pointer;
}

/*        Typography          */
h1 {
  margin-top: 0;
}

/*        reused classes      */
.summaryGridContainer {
  height: 290px;
  margin-top: 30px;
}

.innerTitle {
  position: relative;
  top: 10px;
  left: -2px;
  font-weight: bold;
}


.MuiFormLabel-root{
  &.Mui-required::before {
    content: '*'
  }
}

