@import "../../../../scss/variables.scss";

.rightIconGroup {
  position: absolute;
  padding-right: inherit;
  right: 0;
  display: flex;

  > span {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}
