.todayDivider {
  position: absolute;
  right: -17px;
  bottom: 18px;
  font-weight: bold;

  &Container {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 1px;
    height: 430px;
    bottom: 25px;
    background-color: rgb(100, 100, 100);
  }
}

.eventsCatalystsGrid {
  padding-top: 40px;
}