@import "../../../scss/variables";

.forecastSummaryTitle {
  font-weight: bold;
  color: $grey5;
  text-align: center;
}

.forecastSummaryFigure {
  margin-top: 15px;
}