@import "../../scss/variables.scss";

.activeSubIcon {
  color: $green1;
}

.activeSubIconBright {
  color: $green2;
}

.inactiveSubIcon {
  color: $grey2;
}

.subscriptionIcon {
  cursor: initial;
  padding-right: 8px;
}
