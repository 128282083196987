// Common colors
$primary: #c94576;
$secondary: #ffffff;

$grey1: #e5e5e5;
$grey2: #bfbfbf;
$grey3: #6a7285;
$grey4: #c7c7c7;
$grey5: #434957;
$grey6: #444957;

$white: #ffffff;

$green1: #66b2bd;
$green2: #4be5c1;

$purple1: #6f4d9a;

$blue1: #0099cc;

// Color pseudonyms
$input-placeholder-color: $grey4;
$input-color: $grey3;
$search-icon-color: #808080;
$dropdown-option-hover-background: rgb(245, 245, 245);
$details-table-border: #c4c7ce;
$filters-panel: #888e9e;
$filter-dropdown-border: #a6aab6;
$filter-grid-divider: #c3c7cf;
$error-color: #BD2624;
$bgc-cancel-button: $grey1;