@import "../../scss/variables.scss";

.headingWrapper {
  display: flex;
  justify-content: left;

  &FilterIcon {
    padding-left: 10px;
    padding-top: 5px;
  }
}

.panel {
  margin-bottom: 40px;
  padding-bottom: 20px;
  overflow: hidden;

  &Header {
    padding: 15px;
    height: 60px;
    position: relative;
    border-bottom: 1px solid $grey1;
  }

  &Body {
    height: calc(100% - 40px);
    padding: 0 20px;
    flex: 1 1 auto;
  }

  &TitleContainer {
    display: flex;
    align-items: center;
  }

  &TitleCaption {
    margin-left: 20px;
    margin-top: 2px;
  }
}

.small {
  height: 200px;
}

.medium {
  height: 440px;
}

.large {
  height: 500px;
}

.auto {
  min-height: 200px;
  height: auto;
}

.none {
  height: 110px;
}

.collapsed {
  height: 60px;
}

.noPaddingBottom {
  padding-bottom: 0;
}
