.genderDistributionStack {
  position: relative;
  width: 210px;
  height: 320px;

  &Measurer,
  &Content {
    width: 100%;
    position: absolute;
  }

  &Measurer {
    bottom: 0;
    background-color: #e0e0e0;
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}
